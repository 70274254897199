import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import _ from "lodash";
import qs from "qs";
import { css } from "aphrodite/no-important";

import { verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase.js";
import Logo from "../../components/Logo.js";
import Message from "../../components/Message.js";
import RandomImage from "../../components/RandomImage.js";
import { styles as ButtonStyles } from "../../components/Button.css.js";
import { styles as AuthStyles } from "../../components/Auth.css.js";

function ResetPassword(props) {
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");

  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const actionCode = query.oobCode;
  const errorMessages = {
    default: "Het is niet gelukt om je password te resetten, probeer het later opnieuw.",
    "auth/argument-error": "De reset link die u gebruikt is ongeldig. Vraag een nieuwe aan.",
    "auth/invalid-action-code": "Helaas! Net te laat, deze reset link is verlopen. Vraag een nieuwe aan.",
  };

  useEffect(() => {
    async function validateCode() {
      try {
        await verifyPasswordResetCode(auth, actionCode);
      } catch (err) {
        console.error(err);
        setError(errorMessages[err.code] || errorMessages["default"]);
      }
    }

    validateCode();
  }, []);

  // If authenticated redirect the user to its orginal destination.
  if (auth.currentUser) {
    return <Navigate replace to={_.get(location.state, "from", "/")} />;
  }

  async function onSubmit(event) {
    event.preventDefault();
    setError("");

    if (password !== passwordCheck) {
      setError("De velden komen niet overeen.");
      return;
    }

    try {
      setInfo("Uw reset code wordt nu geverifieerd...");
      const email = await verifyPasswordResetCode(auth, actionCode);
      await confirmPasswordReset(auth, actionCode, password);
      setInfo("Uw reset code is goedgekeurd, we sturen u terug naar het dashboard.");
      await signInWithEmailAndPassword(auth, email, password);
      setInfo("Het resetten is gelukt u word doorgestuurd!");
    } catch (err) {
      console.error(err);
      setError(errorMessages[err.code] || errorMessages["default"]);
    }
  }

  return (
    <div className={css(AuthStyles.Auth)}>
      <RandomImage />
      <div className={css(AuthStyles.Auth__right)}>
        <div>
          <div className={css(AuthStyles.Auth__logo)}>
            <Logo />
          </div>
          <form id="reset-password" onSubmit={onSubmit} className={css(AuthStyles.Auth__form)}>
            <h3 className={css(AuthStyles.Auth__title)}>Kies je nieuwe wachtwoord.</h3>
            {!!info && <p>{info}</p>}
            {!!error && <Message text={error} />}
            <div className={css(AuthStyles.Auth__row)}>
              <input
                name="password"
                type="password"
                className={css(AuthStyles.Auth__textfield)}
                minLength="8"
                placeholder="Nieuw wachtwoord"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                name="password-check"
                type="password"
                className={css(AuthStyles.Auth__textfield)}
                minLength="8"
                placeholder="Nieuw wachtwoord bevestigen"
                value={passwordCheck}
                onChange={(e) => setPasswordCheck(e.target.value)}
              />
            </div>
            <button id="submit-btn" type="submit" className={css(ButtonStyles.Button)}>
              Wachtwoord wijzigen
            </button>
            <Link to="/login" className={css(AuthStyles.Auth__link)}>
              Annuleren
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
