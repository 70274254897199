import React from "react";
import { css } from "aphrodite/no-important";
import { styles } from "./Button.css.js";

function Button(props) {
  return (
    <button
      onClick={props.onClick}
      type={props.type}
      disabled={props.pending}
      className={css(styles.Button, props.pending && styles["Button--is-loading"], props.ghost && styles["Button__ghost"])}
    >
      {props.pending && (
        <div className={props.ghost ? "mini-spinner-ghost" : "mini-spinner"}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {props.pending ? props.loadingLabel : props.label}
    </button>
  );
}

export default Button;
