import { StyleSheet } from "aphrodite/no-important";
import { colors } from "../styles.js";

const styles = StyleSheet.create({
  Button: {
    display: "inline-block",
    borderRadius: "5px",
    background: colors.primary,
    color: colors.white,
    padding: "1rem 1.5rem",
    marginRight: "1rem",
    border: `1px solid ${colors.primary}`,
    cursor: "pointer",
  },
  Button__ghost: {
    background: "transparent",
    color: colors.primary,
    display: "inline-block",
    borderRadius: "5px",
    padding: "1rem 1.5rem",
    marginRight: "1rem",
    border: `1px solid ${colors.primary}`,
    cursor: "pointer",
  },
  "Button--is-loading": {
    paddingLeft: "3rem",
    cursor: "wait",
  },
});

export { styles };
