import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { css } from "aphrodite/no-important";
import _ from "lodash";
import "normalize.css";

import { auth } from "../../firebase.js";
import Logo from "../../components/Logo.js";
import MainNav from "../../components/MainNav";
import "../../style/global.css";
import { styles } from "./App.css.js";

function App(props) {
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isPublic = _.includes(props.publicRoutes, location.pathname);

  async function logout() {
    try {
      await signOut(auth);
    } catch (err) {
      console.error(err);
    }
    navigate("/login");
  }

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => setInitialized(true));
  }, []);

  // Show spinner on page load so we don't flash the login screen when the user is loggedin.
  if (!initialized) {
    return (
      <span className={css(styles.App__waiting)}>
        <img alt="loading page" src="/static/waiting.png" />
      </span>
    );
  }

  return (
    <div className={css(styles.App)}>
      <Helmet bodyAttributes={{ class: ["Page"] }} />

      {!isPublic && (
        <div className={css(styles.App__topbar)}>
          <div className={css(styles.App__logo)}>
            <Logo />
          </div>
          <div className={css(styles.App__nav)}>
            <button className={css(styles.App__logout)} title={auth?.currentUser?.email + " uitloggen"} onClick={logout}>
              Uitloggen
            </button>
          </div>
        </div>
      )}

      <div className={css(styles.App__content, isPublic && styles["App__content--is-not-logged-in"])}>
        <MainNav isPublic={isPublic} />
        {props.children}
      </div>
    </div>
  );
}

export default App;
