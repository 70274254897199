import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { sendEmailVerification } from "firebase/auth";
import _ from "lodash";
import slug from "slug";
import axios from "axios";
import { css } from "aphrodite/no-important";
import arrayMove from "lodash-move";
import MicroModal from "react-micro-modal";

import { auth, db } from "../../firebase.js";
import i18n from "../../i18n.js";
import Gallery from "./components/Gallery.js";
import VenueSelect from "../../components/VenueSelect.js";
import Button from "../../components/Button.js";
import Message from "../../components/Message.js";
import Tips from "./components/Tips.js";

import { styles } from "./Venue.css.js";
import { styles as ModalStyles } from "./components/Modal.css.js";

const errorMessages = {
  "auth/too-many-requests":
    "Er is al een verificatie email verstuurd naar uw mail adres. Indien u niets heeft ontvangen (check ook uw spam) probeer het dan over 5 minuten nog eens door op de ondestaande knop te drukken.",
};

const initialVenue = {
  name: "",
  address: {
    street: "",
    postalCode: "",
    province: "",
    houseNumber: "",
    city: "",
  },
  telephone: "",
  description: {
    nl: "",
  },
  website: "",
  email: "",
  photos: [],
  accessibility: [],
  types: [],
  features: [],
  maxCapacity: 0,
  minCapacity: 0,
};

function getGeoLocation(q) {
  const params = {
    address: q,
    language: "nl",
    region: "nl",
    key: "AIzaSyBEj2rSFFx5rEC7clt3gohLzMmumsyHh2k",
  };

  return axios.get("https://maps.googleapis.com/maps/api/geocode/json", { params }).then((response) => {
    if (response.data.results.length) {
      return response.data.results[0].geometry.location;
    }

    return response.data;
  });
}

function Venue(props) {
  const [pending, setPending] = useState(false);
  const [saved, setSaved] = useState(false);
  const [pendingRemove, setPendingRemove] = useState(false);
  const [venue, setVenue] = useState(initialVenue);
  const [error, setError] = useState("");
  const [errorVerificationEmail, setErrorVerificationEmail] = useState("");
  const [emailSend, setEmailSend] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  async function onSubmit(event) {
    event && event.preventDefault();

    setError("");
    setPending(true);
    setSaved(false);

    const data = {
      ..._.omit(venue, ["objectID"]),
    };
    if (data.name) {
      data.slug = slug(data.name, { lower: true });
    }
    if (data.address && data.address.street && data.address.houseNumber && data.address.postalCode && data.address.city) {
      let loc;
      try {
        loc = await getGeoLocation(`${data.address.street} ${data.address.houseNumber}, ${data.address.postalCode} ${data.address.city}`);
      } catch (err) {
        setError("Controleer de adres gegevens van uw locatie.");
        console.log(err);
        return;
      }

      if (loc && loc.lng && loc.lat) {
        data._geoloc = {
          lng: parseFloat(loc.lng.toPrecision(6)),
          lat: parseFloat(loc.lat.toPrecision(5)),
        };
      }
    }

    try {
      await updateDoc(doc(db, "locations", venue.objectID), data);
      setSaved(true);
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setPending(false);
  }

  async function onRemove(event) {
    event && event.preventDefault();

    if (!window.confirm("Weet u zeker dat u deze locatie wilt verwijderen?")) {
      return;
    }
    setPendingRemove(true);
    try {
      await deleteDoc(doc(db, "locations", venue.objectID));
      window.scrollTo(0, 0);
      navigate(`/venues`);
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setPendingRemove(false);
  }

  async function onVerifyEmail() {
    setEmailSend(false);
    setErrorVerificationEmail("");
    try {
      await sendEmailVerification(auth.currentUser);
      setEmailSend(true);
    } catch (err) {
      console.log(err.message);
      setErrorVerificationEmail(errorMessages[err.code] || err.message);
    }
  }

  useEffect(() => {
    async function getData() {
      setError("");

      if (!params.venueId) {
        setVenue(initialVenue);
        return;
      }

      try {
        const locDoc = await getDoc(doc(db, "locations", params.venueId));
        if (!locDoc.exists()) {
          setError(`De locatie met id '${params.venueId}' bestaat niet (meer).`);
          setVenue(initialVenue);
          return;
        }

        setVenue({
          ..._.cloneDeep(initialVenue),
          objectID: locDoc.id,
          ...locDoc.data(),
        });
      } catch (err) {
        console.log(err);
        setError(err.message);
        console.log(err);
      }
    }
    getData();
  }, [params.venueId]);

  if (!venue.objectID) {
    return (
      <div>
        <VenueSelect
          activeVenueId={params.venueId}
          onChange={(e, doc) => {
            if (!doc) {
              navigate(`/venues`);
              return;
            }
            navigate(`/venues/${doc.objectID}`);
          }}
          showNewButton={true}
        />
        {error && <Message text={error} />}
      </div>
    );
  }

  return (
    <div>
      <VenueSelect
        activeVenueId={params.venueId}
        onChange={(e, doc) => {
          if (!doc) {
            navigate(`/venues`);
            return;
          }
          navigate(`/venues/${doc.objectID}`);
        }}
        showNewButton={true}
      />
      <div className={css(styles.Venue)}>
        <MicroModal open={saved}>
          {(close) => (
            <div className={css(ModalStyles["Modal"])}>
              <h3 className={css(ModalStyles["Modal__title"])}>Gelukt!</h3>
              <p className={css(ModalStyles["Modal__paragraph"])}>Uw wijzigingen zijn opgeslagen.</p>
              <Button type="submit" label="Sluiten" onClick={() => setSaved(false)} />
            </div>
          )}
        </MicroModal>

        <MicroModal open={emailSend}>
          {(close) => (
            <div className={css(ModalStyles["Modal"])}>
              <h3 className={css(ModalStyles["Modal__title"])}>Email verzonden</h3>
              <p className={css(ModalStyles["Modal__paragraph"])}>
                Er is een email verzonden naar uw emailadres. Klik op de link in de email om uw emailadres te bevestigen.
              </p>
              <Button type="submit" label="Sluiten" onClick={() => setEmailSend(false)} />
            </div>
          )}
        </MicroModal>

        {auth?.currentUser && !auth.currentUser.emailVerified && (
          <div className={css(styles.Venue__message)}>
            <strong>Bevestig uw emailadres</strong>
            <p>
              Voordat wij uw locatie online zetten is het nodig om uw emailadres te bevestigen. Gebruik hiervoor de email die u eerder
              ontvangen heeft of gebruik onderstaande link.
            </p>
            <p>{errorVerificationEmail}</p>
            <span className={css(styles.Venue__message__link)} title="Email opnieuw verzenden" onClick={() => onVerifyEmail()}>
              Deze email opnieuw verzenden
            </span>
          </div>
        )}

        {venue.objectID && !pending && !venue.published && (
          <p className={css(styles.Venue__message)}>
            <strong>Binnenkort online - {venue.name}</strong>
            <br />
            Uw locatie wordt na controle binnen 24 uur online gezet.
          </p>
        )}

        {venue.objectID && !pending && !venue.published && venue.photos.length === 0 && (
          <p className={css(styles.Venue__message)}>
            <strong>Vergeet geen foto te uploaden voor {venue.name}</strong>
            <br />
            Wij hebben tenminste 1 foto nodig om uw locatie op feestjegeven.nl te plaatsen.
          </p>
        )}

        {venue.objectID && venue.published && (
          <a
            target="_blank"
            className={css(styles.Venue__preview)}
            rel="noopener noreferrer"
            href={`https://feestjegeven.nl/l/${venue.slug}/${venue.objectID}`}
          >
            Bekijk online
          </a>
        )}

        <form method="POST" onSubmit={onSubmit} className={css(styles.Venue__form)}>
          <div className={css(styles.Venue__card)}>
            <div className={css(styles.Venue__fields)}>
              <div className={css(styles.Venue__row)}>
                <label>
                  <span className={css(styles.Venue__label)}>Naam</span>
                  <input
                    type="text"
                    name="name"
                    placeholder="Naam van de locatie"
                    required
                    value={venue.name}
                    onChange={(e) => setVenue({ ...venue, name: e.target.value })}
                    className={css(styles.Venue__textfield)}
                  />
                </label>
              </div>

              <div>
                <div className={css(styles.Venue__row, styles["Venue__row--is-4-1"])}>
                  <label>
                    <span className={css(styles.Venue__label)}>Straat</span>
                    <input
                      type="text"
                      name="address.street"
                      required
                      value={venue.address.street}
                      onChange={(e) =>
                        setVenue({
                          ...venue,
                          address: { ...venue.address, street: e.target.value },
                        })
                      }
                      className={css(styles.Venue__textfield)}
                    />
                  </label>
                  <label>
                    <span className={css(styles.Venue__label)}>Nr.</span>
                    <input
                      type="text"
                      name="address.houseNumber"
                      required
                      value={venue.address.houseNumber}
                      onChange={(e) =>
                        setVenue({
                          ...venue,
                          address: {
                            ...venue.address,
                            houseNumber: e.target.value,
                          },
                        })
                      }
                      className={css(styles.Venue__textfield)}
                    />
                  </label>
                </div>
                <div className={css(styles.Venue__row, styles["Venue__row--is-1-3"])}>
                  <label>
                    <span className={css(styles.Venue__label)}>Postcode</span>
                    <input
                      type="text"
                      name="address.postalCode"
                      required
                      value={venue.address.postalCode}
                      onChange={(e) =>
                        setVenue({
                          ...venue,
                          address: {
                            ...venue.address,
                            postalCode: e.target.value,
                          },
                        })
                      }
                      className={css(styles.Venue__textfield)}
                    />
                  </label>
                  <label>
                    <span className={css(styles.Venue__label)}>Plaats</span>
                    <input
                      type="text"
                      name="address.city"
                      required
                      value={venue.address.city}
                      onChange={(e) =>
                        setVenue({
                          ...venue,
                          address: { ...venue.address, city: e.target.value },
                        })
                      }
                      className={css(styles.Venue__textfield)}
                    />
                  </label>
                </div>

                <div className={css(styles.Venue__row)}>
                  <label>
                    <span className={css(styles.Venue__label)}>Provincie</span>
                    <select
                      name="address.province"
                      required
                      value={venue.address.province}
                      onChange={(e) =>
                        setVenue({
                          ...venue,
                          address: {
                            ...venue.address,
                            province: e.target.value,
                          },
                        })
                      }
                      className={css(styles.Venue__select)}
                    >
                      <option value="">--- Kies provincie ---</option>
                      <option value="Drenthe">Drenthe</option>
                      <option value="Flevoland">Flevoland</option>
                      <option value="Friesland">Friesland</option>
                      <option value="Gelderland">Gelderland</option>
                      <option value="Groningen">Groningen</option>
                      <option value="Limburg">Limburg</option>
                      <option value="Noord-Brabant">Noord-Brabant</option>
                      <option value="Noord-Holland">Noord-Holland</option>
                      <option value="Overijssel">Overijssel</option>
                      <option value="Utrecht">Utrecht</option>
                      <option value="Zeeland">Zeeland</option>
                      <option value="Zuid-Holland">Zuid-Holland</option>
                    </select>
                  </label>
                </div>
                <div className={css(styles.Venue__row)}>
                  <label>
                    <span className={css(styles.Venue__label)}>Website</span>
                    <input
                      type="text"
                      name="website"
                      required
                      value={venue.website || ""}
                      onChange={(e) => setVenue({ ...venue, website: e.target.value })}
                      className={css(styles.Venue__textfield)}
                    />
                  </label>
                  {!venue.premium && (
                    <a href="/membership" className={css(styles.Venue__premium_message)}>
                      <strong className={css(styles.Venue__premium_label)}>Premium feature</strong>
                      <br /> Upgrade naar een Premium lidmaatschap voor deze locatie om een link naar uw website te tonen
                    </a>
                  )}
                </div>
                <div className={css(styles.Venue__row)}>
                  <label>
                    <span className={css(styles.Venue__label)}>Email</span>
                    <input
                      type="text"
                      name="email"
                      required
                      value={venue.email}
                      onChange={(e) => setVenue({ ...venue, email: e.target.value })}
                      className={css(styles.Venue__textfield)}
                    />
                  </label>
                </div>
                <div className={css(styles.Venue__row)}>
                  <label>
                    <span className={css(styles.Venue__label)}>Telefoon</span>
                    <input
                      type="text"
                      name="telephone"
                      required
                      value={venue.telephone}
                      onChange={(e) => setVenue({ ...venue, telephone: e.target.value })}
                      className={css(styles.Venue__textfield)}
                    />
                  </label>
                  {!venue.premium && (
                    <a href="/membership" className={css(styles.Venue__premium_message)}>
                      <strong className={css(styles.Venue__premium_label)}>Premium feature</strong>
                      <br /> Upgrade naar een Premium lidmaatschap voor deze locatie om het telefoonnummer tonen
                    </a>
                  )}
                </div>

                {auth?.currentUser?.uid === "G6Ll0zQOXEajm8i0eiP7oHdL0Nk2" && (
                  <div className={css(styles.Venue__row, styles["Venue__row--is-last-row"], styles.Venue__row_admin)}>
                    <a
                      href={`http://www.google.com/search?q="${venue.name}"+${venue.address.city}&tbm=isch&tbs=isz:lt,islt:2mp`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b>Google Image Search</b>
                    </a>
                    <a href={`http://www.google.com/search?q="${venue.name}"+${venue.address.city}`} target="_blank" rel="noreferrer">
                      <b>Google Search</b>
                    </a>
                    {venue._geoloc && (
                      <div className={css(styles.Venue__row, styles.Venue__grey)}>
                        longitude: {venue._geoloc.lng}, latitude: {venue._geoloc.lat}
                      </div>
                    )}
                  </div>
                )}

                <div className={css(styles.Venue__row, styles["Venue__row--is-last-row"])}>
                  <div>
                    {error && <Message text={error} />}
                    <Button type="submit" label="Opslaan" onClick={onSubmit} pending={pending} loadingLabel="Een momentje.." />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={css(styles.Venue__card)}>
            <div className={css(styles.Venue__gallery, styles.Venue__row)}>
              <Tips />
            </div>

            <div className={css(styles.Venue__gallery, styles.Venue__row)}>
              <span className={css(styles.Venue__label)}>Voeg foto's toe *</span>
              <Gallery
                objectID={params.venueId}
                images={venue.photos}
                onImageRemove={(id) => {
                  setVenue({ ...venue, photos: _.without(venue.photos, id) });
                }}
                onImageAdd={(id) => {
                  setVenue({ ...venue, photos: [...venue.photos, id] });
                }}
                onSortEnd={({ active, over }) => {
                  if (active.id !== over.id) {
                    const oldIndex = venue.photos.indexOf(active.id);
                    const newIndex = venue.photos.indexOf(over.id);
                    setVenue({
                      ...venue,
                      photos: arrayMove(venue.photos, oldIndex, newIndex),
                    });
                  }
                }}
              />
              <p>
                <strong>LET OP</strong>
                <br /> Wij hebben tenminste 1 foto van minimaal 1600x800 nodig.
              </p>
              {!venue.premium && (
                <a href="/membership" className={css(styles.Venue__premium_message)}>
                  <strong className={css(styles.Venue__premium_label)}>Premium feature</strong>
                  <br /> Upgrade naar een Premium lidmaatschap voor deze locatie om meer dan 2 foto's te laten zien
                </a>
              )}
            </div>

            <div className={css(styles.Venue__row, styles["Venue__row--is-last-row"])}>
              <div>
                {error && <Message text={error} />}
                <Button type="submit" label="Opslaan" onClick={onSubmit} pending={pending} loadingLabel="Een momentje.." />
              </div>
            </div>
          </div>

          <div className={css(styles.Venue__card)}>
            <div className={css(styles.Venue__row)}>
              <label>
                <span className={css(styles.Venue__label)}>Beschrijving</span>
                <textarea
                  name="description"
                  required
                  rows="10"
                  placeholder="Wat maakt deze locatie zo uniek? Waarom zou ik juist deze locatie willen boeken?"
                  onChange={(e) =>
                    setVenue({
                      ...venue,
                      description: { ...venue.description, nl: e.target.value },
                    })
                  }
                  className={css(styles.Venue__textarea)}
                  value={venue.description.nl}
                />
              </label>
              <p>Vertel iets over uw locatie, wat maakt deze locatie zo uniek? Voor wat voor gelegenheden is uw locatie te boeken?</p>
            </div>
            <div className={css(styles.Venue__row, styles["Venue__row"])}>
              <label>
                <span className={css(styles.Venue__label)}>Minimaal aantal personen</span>
                <input
                  type="text"
                  name="minCapacity"
                  required
                  value={venue.minCapacity}
                  onChange={(e) => setVenue({ ...venue, minCapacity: e.target.value })}
                  className={css(styles.Venue__textfield)}
                />
              </label>
            </div>
            <div className={css(styles.Venue__row, styles["Venue__row"])}>
              <label>
                <span className={css(styles.Venue__label)}>Maximaal aantal personen</span>
                <input
                  type="text"
                  name="maxCapacity"
                  required
                  value={venue.maxCapacity}
                  onChange={(e) => setVenue({ ...venue, maxCapacity: e.target.value })}
                  className={css(styles.Venue__textfield)}
                />
              </label>
            </div>

            <div className={css(styles.Venue__row, styles["Venue__row--is-last-row"])}>
              <div>
                {error && <Message text={error} />}
                <Button type="submit" label="Opslaan" onClick={onSubmit} pending={pending} loadingLabel="Een momentje.." />
              </div>
            </div>
          </div>

          <div className={css(styles.Venue__card)}>
            <div className={css(styles.Venue__row)}>
              <div>
                <span className={css(styles.Venue__label)}>Kenmerken</span>
                <span className={css(styles.Venue__checkboxes)}>
                  {_.map(i18n.features, (feature, i) => (
                    <label
                      key={`feature-label-${i}`}
                      className={css(styles.Venue__checkbox, _.includes(venue.features, feature) && styles["Venue__checkbox--is-checked"])}
                    >
                      <input
                        key={`feature-input-${i}`}
                        type="checkbox"
                        name={feature}
                        className={css(styles["Venue__input--is-checkbox"])}
                        checked={_.includes(venue.features, feature)}
                        onChange={(e) =>
                          setVenue({
                            ...venue,
                            features: _.xor(venue.features, [e.target.name]),
                          })
                        }
                      />
                      {i}
                    </label>
                  ))}
                </span>
              </div>
            </div>

            <div className={css(styles.Venue__row)}>
              <div>
                <span className={css(styles.Venue__label)}>Type locatie</span>
                <span className={css(styles.Venue__checkboxes)}>
                  {_.map(i18n.types, (type, i) => (
                    <label
                      key={`types-label-${i}`}
                      className={css(styles.Venue__checkbox, _.includes(venue.types, type) && styles["Venue__checkbox--is-checked"])}
                    >
                      <input
                        key={`types-input-${i}`}
                        type="checkbox"
                        name={type}
                        className={css(styles["Venue__input--is-checkbox"])}
                        checked={_.includes(venue.types, type)}
                        onChange={(e) =>
                          setVenue({
                            ...venue,
                            types: _.xor(venue.types, [e.target.name]),
                          })
                        }
                      />
                      {i}
                    </label>
                  ))}
                </span>
              </div>
            </div>

            <div className={css(styles.Venue__row)}>
              <div>
                <span className={css(styles.Venue__label)}>Toegankelijkheid</span>
                <span className={css(styles.Venue__checkboxes)}>
                  {_.map(i18n.accessibility, (item, i) => (
                    <label
                      key={`accessibility-label-${i}`}
                      className={css(
                        styles.Venue__checkbox,
                        _.includes(venue.accessibility, item) && styles["Venue__checkbox--is-checked"],
                      )}
                    >
                      <input
                        key={`accessibility-input-${i}`}
                        type="checkbox"
                        name={item}
                        className={css(styles["Venue__input--is-checkbox"])}
                        checked={_.includes(venue.accessibility, item)}
                        onChange={(e) =>
                          setVenue({
                            ...venue,
                            accessibility: _.xor(venue.accessibility, [e.target.name]),
                          })
                        }
                      />
                      {i}
                    </label>
                  ))}
                </span>
              </div>
            </div>

            <div className={css(styles.Venue__row, styles["Venue__row--is-last-row"])}>
              <div>
                {error && <Message text={error} />}
                <Button type="submit" label="Opslaan" onClick={onSubmit} pending={pending} loadingLabel="Een momentje.." />
              </div>
            </div>
          </div>

          <div className={css(styles.Venue__card)}>
            <div className={css(styles.Venue__row, styles["Venue__row--is-last-row"])}>
              <div>
                <span className={css(styles.Venue__label)}>Deze locatie verwijderen</span>
                <p>Met de onderstaande actie wordt deze locatie en de bijbehorende gegevens permanent van je account verwijderd.</p>

                {error && <Message text={error} />}
                <Button
                  type="submit"
                  label="Verwijderen"
                  onClick={onRemove}
                  pending={pendingRemove}
                  loadingLabel="Wordt verwijderd.."
                  ghost={true}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Venue;
