import { StyleSheet } from "aphrodite/no-important";
import { colors, setSvgBackground } from "../styles.js";

const styles = StyleSheet.create({
  VenueSelect: {
    marginBottom: "3rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  VenueSelect__select: {
    display: "block",
    fontSize: "2.3rem",
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: "500",
    lineHeight: "2rem",
    padding: "0.8em 0.8em 0.8em 5rem",
    width: "100%",
    color: colors.textColor,
    maxWidth: "100%",
    marginRight: "2rem",
    boxSizing: "border-box",
    border: `1px solid #ddd`,
    boxShadow: "0 7px 14px 0 rgba(185,174,162, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07)",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundColor: "transparent",
    background: setSvgBackground("select", "2rem", "50%"),
    transition: "background-color 0.3s ease",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "white",
    },
  },
  "VenueSelect__select--is-standalone": {
    marginRight: "0",
  },
  VenueSelect__button: {
    fontWeight: "600",
    fontSize: "1.8rem",
    backgroundColor: colors.primary,
    lineHeight: "3.6rem",
    display: "inline-block",
    whiteSpace: "nowrap",
    margin: "0",
  },
  VenueSelect__title: {
    textTransform: "uppercase",
    fontSize: "1.3rem",
    color: colors.textColor,
    marginBottom: "1rem",
  },
});

export { styles };
