import React, { useMemo } from "react";
import { styles as AuthStyles } from "./Auth.css.js";
import { css } from "aphrodite/no-important";
import { randomImages } from "../styles.js";

function RandomImage() {
  const random = useMemo(() => Math.floor(Math.random() * randomImages.length), []);
  return (
    <div
      className={css(AuthStyles.Auth__left)}
      style={{
        backgroundImage: "url(" + randomImages[random] + ")",
        backgroundSize: "cover",
        height: "100%",
      }}
    ></div>
  );
}

export default RandomImage;
