import { StyleSheet } from "aphrodite/no-important";
import { colors } from "../styles.js";

const styles = StyleSheet.create({
  Auth: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 50%) minmax(320px, 50%)",
    height: "100vh",
    "@media (max-width: 680px)": {
      gridTemplateColumns: "none",
      overflow: "auto",
      marginBottom: "3rem",
      display: "block",
      height: "100%",
    },
  },
  Auth__left: {
    objectFit: "cover",
    overflow: "hidden",
    position: "relative",
    "@media (max-width: 680px)": {
      display: "none",
    },
  },
  Auth__image: {
    display: "block",
    position: "absolute",
    left: "-50%",
    zIndex: "-1",
    top: "-20%",
  },
  Auth__right: {
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5rem",
    "@media (max-width: 680px)": {
      display: "block",
      width: "100%",
      padding: "3rem",
    },
  },
  Auth__form: {
    display: "block",
  },
  Auth__logo: {
    marginBottom: "3rem",
  },
  Auth__title: {
    fontSize: "1.8rem",
    fontWeight: "300",
    marginBottom: "1rem",
  },
  "Auth__subtitle--is-hightlighted": {
    color: colors.primary,
    fontSize: "1.8rem",
  },
  Auth__subtitle: {
    fontSize: "2rem",
    fontWeight: "500",
    marginBottom: "1rem",
  },
  Auth__paragraph: {
    fontSize: "1.8rem",
    fontWeight: "300",
    lineHeight: "1.345em",
    padding: "0",
    marginTop: "0",
  },
  Auth__list: {
    fontSize: "1.8rem",
    fontWeight: "300",
    marginBottom: "2rem",
    lineHeight: "2.5rem",
  },
  Auth__link: {
    color: colors.primary,
    display: "inline-block",
    paddingTop: "1rem",
  },
  Auth__textfield: {
    background: colors.background,
    border: "none",
    padding: "1rem 1.5rem",
    width: "100%",
    display: "block",
    borderTop: "1px solid #ece9e5",
    borderLeft: "1px solid #ece9e5",
    "::placeholder": {
      color: colors.placeholder,
    },
  },
  Auth__label: {
    display: "block",
    paddingBottom: "0.8rem",
    fontWeight: "300",
  },
  Auth__row: {
    display: "grid",
    gridGap: "0 2rem",
    marginBottom: "2.6rem",
  },
});

export { styles };
