import React, { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { css } from "aphrodite/no-important";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase.js";
import Logo from "../../components/Logo.js";
import RandomImage from "../../components/RandomImage.js";
import Message from "../../components/Message.js";
import { styles as ButtonStyles } from "../../components/Button.css.js";
import { styles as AuthStyles } from "../../components/Auth.css.js";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");

  const location = useLocation();

  async function onSubmit(event) {
    event.preventDefault();
    setError("");
    setInfo("");

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setInfo("Het inloggen is gelukt u word doorgestuurd!");
    } catch (err) {
      console.log(err);
      setError("Oeps! Deze gegevens zijn onjuist.");
    }
  }

  // If authenticated redirect the user to its orginal destination.
  if (auth.currentUser) {
    return <Navigate replace to={_.get(location.state, "from", "/")} />;
  }

  return (
    <div className={css(AuthStyles.Auth)}>
      <RandomImage />
      <div className={css(AuthStyles.Auth__right)}>
        <div>
          <div className={css(AuthStyles.Auth__logo)}>
            <Logo />
          </div>
          <form id="login" onSubmit={onSubmit} className={css(AuthStyles.Auth__form)}>
            <h3 className={css(AuthStyles.Auth__title)}>
              Login om je locaties te bekijken.{" "}
              <Link to="/signup" className={css(AuthStyles.Auth__link)}>
                Nog geen lid?
              </Link>
            </h3>
            {!!info && <p>{info}</p>}
            {!!error && <Message text={error} />}
            <div className={css(AuthStyles.Auth__row)}>
              <label>
                <input
                  name="email"
                  type="email"
                  placeholder="Emailadres"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={css(AuthStyles.Auth__textfield)}
                />
              </label>
              <label>
                <input
                  name="password"
                  type="password"
                  placeholder="Wachtwoord"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={css(AuthStyles.Auth__textfield)}
                />
              </label>
              <Link to="/forgot-password" className={css(AuthStyles.Auth__link)}>
                Wachtwoord vergeten
              </Link>
            </div>
            <button type="submit" className={css(ButtonStyles.Button)}>
              Inloggen
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
