const colors = {
  signal: "#F3AE4B",
  brown: "#98897a",
  lightBrown: "#e0d9d2",
  white: "#fff",
  darkGray: "#2d2d2d",
  textColor: "#2d2d2de6",
  placeholder: "#2d2d2d6e",
  primary: "#FF3B5C",
  lightPink: "#fbdee1",
  background: "#f8f7f6",
  whites: {
    "10%": "rgba(255, 255, 255, 0.)",
    "20%": "rgba(255, 255, 255, 0.2)",
    "30%": "rgba(255, 255, 255, 0.3)",
    "40%": "rgba(255, 255, 255, 0.4)",
  },
};

// Inheritance
colors.positive = colors.primary;

// Default sizes
const columnSize = "42"; // pixels
const columnSpacing = "15"; // pixels

const sizes = {
  borderRadius: "5px",
  columnSpacing: `${columnSpacing}px`,
  columnSize: `${columnSize}px`,
  columnSpan: (number) => `${columnSize * number + columnSpacing * number}px`,
  chatPicture: "38px",
  profilePicture: "130px",
};

const svgIcons = {
  edit: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="#111111"><path d="M26,31H2a1,1,0,0,1-1-1V6A1,1,0,0,1,2,5H14V7H3V29H25V18h2V30A1,1,0,0,1,26,31Z" fill="#111111"></path> <path data-color="color-2" d="M21.086,5.5l-9.793,9.793a1,1,0,0,0-.241.391l-2,6A1,1,0,0,0,10,23a.987.987,0,0,0,.316-.052l6-2a1,1,0,0,0,.391-.241L26.5,10.914Z"></path> <path data-color="color-2" d="M30.707,5.293l-4-4a1,1,0,0,0-1.414,0L22.5,4.086,27.914,9.5l2.793-2.793A1,1,0,0,0,30.707,5.293Z"></path></g></svg>',
  contact:
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="#111111"><path data-color="color-2" d="M30,15a1,1,0,0,1-1-1A11.013,11.013,0,0,0,18,3a1,1,0,0,1,0-2A13.015,13.015,0,0,1,31,14,1,1,0,0,1,30,15Z"></path> <path data-color="color-2" d="M24,15a1,1,0,0,1-1-1,5.006,5.006,0,0,0-5-5,1,1,0,0,1,0-2,7.008,7.008,0,0,1,7,7A1,1,0,0,1,24,15Z"></path> <path d="M21.028,19.528l-2.445,3.057a25.8,25.8,0,0,1-9.168-9.168l3.057-2.445a2.078,2.078,0,0,0,.6-2.464L10.286,2.235A2.076,2.076,0,0,0,7.865,1.067l-5.3,1.376A2.088,2.088,0,0,0,1.02,4.75,30.815,30.815,0,0,0,27.25,30.98a2.088,2.088,0,0,0,2.306-1.542l1.376-5.3a2.077,2.077,0,0,0-1.167-2.419L23.492,18.93A2.077,2.077,0,0,0,21.028,19.528Z" fill="#111111"></path></g></svg>',
  stats:
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="#111111"><rect x="2" y="3" width="4" height="26" rx="1" ry="1" fill="#111111"></rect> <rect x="8" y="10" width="4" height="19" rx="1" ry="1" data-color="color-2"></rect> <rect x="14" y="17" width="4" height="12" rx="1" ry="1" fill="#111111"></rect> <rect x="20" y="10" width="4" height="19" rx="1" ry="1" data-color="color-2"></rect> <rect x="26" y="17" width="4" height="12" rx="1" ry="1" fill="#111111"></rect></g></svg>',
  plus: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="%23e0d9d2" stroke="%23e0d9d2"><line data-color="color-2" fill="none" stroke-miterlimit="10" x1="16" y1="9" x2="16" y2="23"></line> <line data-color="color-2" fill="none" stroke-miterlimit="10" x1="23" y1="16" x2="9" y2="16"></line> <circle fill="none" stroke="%23e0d9d2" stroke-miterlimit="10" cx="16" cy="16" r="15"></circle></g></svg>',
  star: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="%23FFD92D"><path d="M10.5456 1.65496C10.845 0.733646 12.1484 0.733645 12.4478 1.65496L14.3031 7.36519C14.437 7.77721 14.821 8.05618 15.2542 8.05618H21.2583C22.227 8.05618 22.6298 9.29579 21.8461 9.86519L16.9887 13.3943C16.6382 13.649 16.4915 14.1003 16.6254 14.5123L18.4807 20.2226C18.7801 21.1439 17.7256 21.91 16.9419 21.3406L12.0845 17.8115C11.734 17.5569 11.2594 17.5569 10.9089 17.8115L6.0515 21.3406C5.26779 21.91 4.21331 21.1439 4.51266 20.2226L6.36803 14.5123C6.5019 14.1003 6.35524 13.649 6.00476 13.3943L1.14734 9.86519C0.363627 9.29579 0.766401 8.05618 1.73512 8.05618H7.73922C8.17245 8.05618 8.5564 7.77721 8.69028 7.36519L10.5456 1.65496Z" fill="%23FFD92D"></path></g></svg>',
  add: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="%23ffffff" stroke="%23ffffff"><line data-color="color-2" fill="none" stroke-miterlimit="10" x1="16" y1="9" x2="16" y2="23"></line> <line data-color="color-2" fill="none" stroke-miterlimit="10" x1="23" y1="16" x2="9" y2="16"></line></g></svg>',
  select:
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="%232d2d2de6"><path d="M17.156,8.4,12,4.28,6.844,8.4,5.594,6.843l5.781-4.625a1,1,0,0,1,1.249,0l5.781,4.625Z" fill="%232d2d2de6  "></path> <path data-color="color-2" d="M12,22a1,1,0,0,1-.625-.219L5.594,17.156l1.25-1.562L12,19.72,17.156,15.6l1.25,1.562-5.781,4.625A1,1,0,0,1,12,22Z"></path></g></svg>',
  logout:
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="%23111111"><polygon data-color="color-2" points="11,12.414 15.414,8 11,3.586 9.586,5 11.586,7 5,7 5,9 11.586,9 9.586,11 "></polygon> <path fill="%23111111" d="M12,14H3V2h9V0H2C1.448,0,1,0.448,1,1v14c0,0.552,0.448,1,1,1h10V14z"></path></g></svg>',
  remove:
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="%23ffffff"><rect data-color="color-2" x="5" y="7" width="2" height="6"></rect> <rect data-color="color-2" x="9" y="7" width="2" height="6"></rect> <path fill="%23ffffff" d="M12,1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H0v2h1v10c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V5h1V3h-4V1z M6,2h4 v1H6V2z M13,5v9H3V5H13z"></path></g></svg>',
};

const setSvgBackground = (type, posX = 0, posY = 0) => {
  return `url('data:image/svg+xml;utf8,${svgIcons[type]}') ${posX} ${posY} no-repeat`;
};

const randomImages = [
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/6ih18ckgf0/images/xtaa9qgvsz-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/qrogqob3bg/images/studfio-a12-1-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/s3x37auh75/images/j8htwvqdkh-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/3idgh5662r/images/5u4ktnwy6r-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/wfmrxn26lf/images/y0vgoblj9l-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/es7lreim1h/images/lvq8aa2dyl-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/dcpmbdmej0/images/4wc3ntnwua-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/5hzffvemt5/images/tve0hfjo3l-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/r6fvg28mqj/images/ghalifitih-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/hv5tuiv9m0/images/cr7q2zwtm5-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/hv5tuiv9m0/images/8mizvggh89-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/hv5tuiv9m0/images/mo7pjje0nv-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/hv5tuiv9m0/images/hfy33w5ys3-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/hv5tuiv9m0/images/76eklu7nuf-full@2x.webp",
  "https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/hv5tuiv9m0/images/9nou1rjmil-full@2x.webp",
];

export { colors, sizes, svgIcons, setSvgBackground, randomImages };
