import React from "react";
import { css } from "aphrodite/no-important";
import { styles } from "./Requests.css.js";

class Requests extends React.Component {
  state = {
    venues: [],
    error: "",
  };

  render() {
    return (
      <div className={css(styles.Requests)}>
        <div>
          <div className={css(styles.Requests__request)}>1 dag geleden Virgil Ulsken virgil@prodeksolutions.nl 0627899231</div>
          <div className={css(styles.Requests__request, styles["Requests__request--is-selected"])}>
            Twee weken geleden Marco Fasen mfasen@hotmail.com
          </div>
          <div className={css(styles.Requests__request)}>Drie weken geleden Lianne Uitterlinde liaantje@yahoo.com</div>
        </div>

        <div className={css(styles.Requests__chat)}>Aanvraag 14 september 2019 - 23 personen Graag zou ik een offerte ontvangen</div>
      </div>
    );
  }
}

export default Requests;
