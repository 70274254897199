import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import qs from "qs";

function Action() {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  if (query.mode === "resetPassword") {
    return <Navigate replace to={`/reset-password${location.search}`} />;
  }

  if (query.mode === "verifyEmail") {
    return <Navigate replace to={`/verify-email${location.search}`} />;
  }

  if (query.mode === "signIn") {
    return <Navigate replace to={`/sign-in${location.search}`} />;
  }

  return <Navigate replace to="/" />;
}

export default Action;
