import { StyleSheet } from "aphrodite/no-important";
import { colors, setSvgBackground } from "../../styles.js";

const styles = StyleSheet.create({
  Membership: {},
  Requests__request: {
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, .07)",
    background: colors.white,
    padding: "2rem",
    // borderBottom: `1px solid ${colors.background}`
    marginBottom: "1rem",
  },
  "Requests__request--is-selected": {
    color: colors.primary,
  },

  Venue__card: {
    boxShadow: "0 7px 14px 0 rgba(185,174,162, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07)",
    background: colors.white,
    padding: "3rem",
    marginBottom: "3rem",
    position: "relative",
    cursor: "pointer",
  },

  "Venue__card--is-hightlighted": {
    borderBottom: `5px solid ${colors.primary}`,
  },

  Venue__textfield: {
    background: colors.background,
    border: "none",
    padding: "1rem 1.5rem",
    width: "100%",
    display: "block",
    borderTop: "1px solid #ece9e5",
    borderLeft: "1px solid #ece9e5",
    "::placeholder": {
      color: colors.placeholder,
    },
  },

  Membership_features_list: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    marginBottom: "3rem",
  },

  Membership_features_list_item: {
    background: `${setSvgBackground("star", "0", "0")}`,
    display: "block",
    paddingLeft: "3rem",
    marginBottom: "1rem",
  },

  Venue__row: {
    marginBottom: "2.6rem",
  },

  "Venue__row--is-last-row": {
    marginBottom: "0",
  },

  "Venue__row--is-last-paragraph": {
    marginBottom: "0",
  },

  Venue__label: {
    display: "block",
    paddingBottom: "0.8rem",
    fontWeight: "300",
  },
  Membership__price__card: {
    width: "auto",
    textAlign: "left",
    padding: "2rem",
    "@media (max-width: 800px)": {
      paddingBottom: "4rem",
    },
  },
  Membership__prices: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "2rem",
    "@media (max-width: 580px)": {
      gridTemplateColumns: "1fr",
      gap: "0",
    },
  },
  Membership__pricetitle: {
    color: colors.primary,
    textTransform: "uppercase",
    fontSize: "0.75em",
  },
  Membership__amount: {
    display: "block",
    "font-size": "4.2rem",
    "font-weight": "600",
    margin: "1rem 0 1rem",
  },
  Membership__amount__currency: {
    "font-size": "1.8rem",
    "font-weight": "400",
    color: "#777",
  },
  Membership__frequency: {
    display: "block",
  },
  Membership__invoice_frequency: {
    color: "#bbb",
    paddingTop: "1rem",
    display: "block",
  },

  Membership__subscription_badge: {
    display: "block",
    position: "absolute",
    left: "50%",
    width: "200px",
    height: "30px",
    marginLeft: "-100px",
    lineHeight: "30px",
    textAlign: "center",
    top: 0,
    color: "#fff",
    fontSize: "1rem",
    backgroundColor: colors.primary,
  },

  Membership__badge: {
    display: "block",
    position: "absolute",
    width: "100px",
    right: "0",
    lineHeight: "1.5rem",
    color: "#fff",
    fontSize: "1rem",
    padding: "1rem 1.2rem",
    borderRadius: "5px 0 0 5px",
    backgroundColor: colors.primary,
    "@media (max-width: 800px)": {
      position: "absolute",
      width: "100%",
      bottom: "0",
      borderRadius: "0",
    },
  },
  Membership__badge_title: {
    "font-weight": "600",
    display: "block",
    fontSize: "15px",
  },
});

export { styles };
