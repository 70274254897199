import { StyleSheet } from "aphrodite/no-important";
import { colors, sizes, setSvgBackground } from "../../../styles.js";

const styles = StyleSheet.create({
  Modal: {},
  Modal__paragraph: {
    margin: "0",
    padding: "0 0 2rem 0",
    lineHeight: "2rem",
  },
  Modal__title: {
    fontSize: "2rem",
    padding: "0 0 1rem 0",
    fontWeight: "600",
  },
});

export { styles };
