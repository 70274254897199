import { StyleSheet } from "aphrodite/no-important";
import { colors } from "../styles.js";

const styles = StyleSheet.create({
  Message: {
    color: colors.primary,
    padding: "1rem",
    background: colors.lightPink,
    borderRadius: "3px",
    lineHeight: "1.3em",
  },
});

export { styles };
