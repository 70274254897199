import React, { useState, useEffect } from "react";
import "firebase/firestore";
import { getProducts, createCheckoutSession, getCurrentUserSubscriptions } from "@stripe/firestore-stripe-payments";
import { httpsCallable } from "firebase/functions";
import dayjs from "dayjs";

import { payments, functions } from "../../firebase.js";
import Button from "../../components/Button.js";
import Message from "../../components/Message.js";
import VenueSelect from "../../components/VenueSelect.js";
import { css } from "aphrodite/no-important";
import { styles } from "./Membership.css.js";
import _ from "lodash";

function Membership(props) {
  const [pending, setPending] = useState(false);
  const [venue, setVenue] = useState({});
  const [error, setError] = useState("");

  const [product, setProduct] = useState(false);
  const [subscription, setSubscription] = useState(false);

  const [pendingPortal, setPendingPortal] = useState(false);
  const [pendingSelect, setPendingSelect] = useState(false);

  const today = dayjs();

  async function toStripeCustomerPortal(event) {
    event.preventDefault();
    setPendingPortal(true);

    const funcName = "ext-firestore-stripe-payments-createPortalLink";
    const funcRef = httpsCallable(functions, funcName);
    try {
      const resp = await funcRef({
        returnUrl: window.location.href,
      });
      window.location.assign(resp.data.url);
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setPendingPortal(false);
  }

  async function onProductSelect(event, priceID) {
    event.preventDefault();

    setPendingSelect(true);
    try {
      const session = await createCheckoutSession(payments, {
        mode: "subscription",
        automatic_tax: true,
        allow_promotion_codes: true,
        price: priceID,
        success_url: window.location.href,
        cancel_url: window.location.href,
        metadata: {
          venue: venue.objectID,
        },
      });
      window.location.assign(session.url);
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  }

  useEffect(() => {
    async function getData() {
      setPending(true);
      try {
        const subs = await getCurrentUserSubscriptions(payments, {
          status: ["active", "unpaid", "trialing"],
        });
        const sub = _.find(subs, (s) => s.metadata.venue === venue.objectID);
        setSubscription(sub);
      } catch (err) {
        console.log(err);
        setError(err.message);
      }

      try {
        const products = await getProducts(payments, {
          includePrices: true,
          activeOnly: true,
        });
        const prices = _.filter(products[0].prices, (price) => {
          return price.active;
        });
        setProduct({
          ...products[0],
          prices: _.map(prices, (price) => ({
            ...price,
            divider: price.interval === "month" ? price.interval_count : price.interval === "year" ? 12 : 1,
          })),
        });
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
      setPending(false);
    }
    getData();
  }, [venue.objectID]);

  return (
    <div className={css(styles.Membership)}>
      <h1>Word nu Premium lid!</h1>
      <p>Een premium lidmaatschap biedt u alle voordelen van Feestjegeven en zorgt ervoor dat u extra goed in de spotlight staat.</p>
      <ul className={css(styles.Membership_features_list)}>
        <li className={css(styles.Membership_features_list_item)}>uw advertentie valt meer op in de zoekresultaten</li>
        <li className={css(styles.Membership_features_list_item)}>
          uw zichtbaarheid wordt op verschillende kanalen door ons vergroot; sociale media en landingspagina’s
        </li>
        <li className={css(styles.Membership_features_list_item)}>bezoekers kunnen u direct contacteren naar keuze</li>
        <li className={css(styles.Membership_features_list_item)}>aanvragen van bezoekers rechtstreeks in uw email</li>
        <li className={css(styles.Membership_features_list_item)}>u komt terecht in onze jaarlijkse magazine vol feestlocaties</li>
        <li className={css(styles.Membership_features_list_item)}>
          wij zorgen er regelmatig voor dat uw pagina er inhoudelijk tip-top uitziet
        </li>
      </ul>

      <VenueSelect
        className={css(styles["VenueSelect__select--is-standalone"])}
        activeVenueId={venue.objectID}
        isStandAlone={true}
        onChange={(_, venue) => {
          setSubscription(false);
          setProduct(false);
          setVenue(venue || {});
        }}
      />

      {pendingSelect && (
        <div className={css(styles.Venue__card, styles["Venue__card--is-hightlighted"])}>
          <div className={css(styles.Venue__row, styles["Venue__row--is-last-row"])}>
            <span className={css(styles.Venue__row, styles["Venue__row--is-last-paragraph"])}>
              <strong>Momentje!</strong> U wordt doorgestuurd naar uw abonnement...
            </span>
          </div>
        </div>
      )}

      {!pendingSelect && !pending && !subscription && venue.objectID && product && (
        <>
          <h2>Kies een abonnement</h2>
          <br />
          <div className={css(styles.Membership__prices)}>
            {_.map(product.prices, (price) => (
              <div
                key={price.id}
                className={css(styles.Venue__card, styles.Membership__price__card)}
                onClick={(e) => onProductSelect(e, price.id)}
              >
                {subscription?.price === price.id && (
                  <div className={css(styles.Membership__subscription_badge)}>
                    <strong>Huidig</strong> - (periode loopt tot {subscription.current_period_end})
                  </div>
                )}
                <div className={css(styles.Membership__option)}>
                  <div className={css(styles.Membership__detail)}>
                    {price.interval === "month" && price.interval_count === 1 && (
                      <h3>
                        <span className={css(styles.Membership__pricetitle)}>PREMIUM</span> <br /> Flexibel
                      </h3>
                    )}
                    {price.interval === "month" && price.interval_count === 6 && (
                      <>
                        <div className={css(styles.Membership__badge)}>
                          <strong className={css(styles.Membership__badge_title)}>Meest populair</strong>
                          (77% klanten)
                        </div>
                        <h3>
                          <span className={css(styles.Membership__pricetitle)}>PREMIUM</span> <br /> Balans
                        </h3>
                      </>
                    )}
                    {price.interval === "year" && (
                      <h3>
                        <span className={css(styles.Membership__pricetitle)}>PREMIUM</span> <br /> Voordeligst
                      </h3>
                    )}
                  </div>
                  <div className={css(styles.Membership__price)}>
                    <span className={css(styles.Membership__amount)}>
                      <span className={css(styles.Membership__amount__currency)}>&euro;</span>{" "}
                      {(price.unit_amount / 100 / price.divider).toFixed()}
                    </span>
                    <span className={css(styles.Membership__frequency)}>Per maand (excl. BTW)</span>
                    {price.interval === "month" && price.interval_count === 1 && (
                      <span className={css(styles.Membership__invoice_frequency)}>Elke maand opzegbaar</span>
                    )}
                    {price.interval === "month" && price.interval_count === 6 && (
                      <span className={css(styles.Membership__invoice_frequency)}>Elke 6 maanden opzegbaar</span>
                    )}
                    {price.interval === "year" && <span className={css(styles.Membership__invoice_frequency)}>Elk jaar opzegbaar</span>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {!subscription && !pending && venue.objectID && !pendingSelect && (
        <div className={css(styles.Venue__card, styles["Venue__card--is-hightlighted"])}>
          <div className={css(styles.Venue__row, styles["Venue__row--is-last-row"])}>
            <h3>Freemium</h3>
            <p className={css(styles.Venue__row, styles["Venue__row--is-last-paragraph"])}>
              Dit is uw huidige abonnement en biedt een basisvermelding op de website. Wilt u meer aanvragen en beter in de spotlight?
              Overweeg dan één van de bovenstaande opties.
            </p>
          </div>
        </div>
      )}

      {!pending && venue.objectID && subscription && (
        <div className={css(styles.Venue__card)}>
          <div className={css(styles.Venue__row, styles["Venue__row--is-last-row"])}>
            <h2>Abonnement & facturen voor {venue.name}</h2>
            {!subscription.cancel_at_period_end && (
              <p>
                Uw abonnement is gestart op
                {dayjs(subscription.current_period_start).format(" D MMMM YYYY ")}
                en loopt tot
                {dayjs(subscription.current_period_end).format(" D MMMM YYYY ")}({-today.diff(subscription.current_period_end, "days")}{" "}
                dagen over) en zal dan automatisch worden verlengd.
              </p>
            )}
            {subscription.cancel_at_period_end && (
              <p>
                Dit abonnement is geannuleerd. Uw abonnement eindigt op {dayjs(subscription.current_period_end).format(" D MMMM YYYY")}.
              </p>
            )}

            {error && <Message text={error} />}
            <Button
              pending={pendingPortal}
              onClick={toStripeCustomerPortal}
              label="Abonnement wijzigen & facturen inzien"
              loadingLabel="Even geduld..."
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Membership;
