import React, { useEffect, useState } from "react";
import { css } from "aphrodite/no-important";
import { styles } from "../Venue.css.js";
import _ from "lodash";

function Tips(props) {
  const [tip, setTip] = useState(_.random(1, 5));

  useEffect(() => {
    const timer = setInterval(() => {
      setTip(tip === 5 ? 1 : tip + 1);
    }, 12000);
    return () => clearInterval(timer);
  });

  return (
    <>
      {tip === 1 && (
        <div>
          <h4>Tip: Foto's zijn belangrijk</h4>
          <p>
            Het is een understatement om te zeggen dat goede foto’s van je ruimte belangrijk zijn. Foto’s zijn het allereerste waar mensen
            naar kijken als ze een locatie zoeken en ze zijn van grote invloed op hun uiteindelijke keuze om je locatie juist wel, of niet,
            te reserveren.
          </p>
        </div>
      )}

      {tip === 2 && (
        <div>
          <h4>Tip: Laat de binnenkant stralen</h4>
          <p>
            Goed licht is de beste vriend van elke fotograaf. Het benadrukt de natuurlijke diepte, kleur en contrast van een ruimte. Goed
            licht zorgt er bovendien voor dat je foto er professioneel uitziet, en jij dus ook.
          </p>
        </div>
      )}

      {tip === 3 && (
        <div>
          <h4>Tip: Benadruk unieke kenmerken</h4>
          <p>Heeft uw locatie een mooi terras? Een geweldige keuken? Of een adembenemend uitzicht? Laat het weten met foto’s.</p>
        </div>
      )}

      {tip === 4 && (
        <div>
          <h3>Tip: Neem foto’s buiten</h3>
          <p>De buitenkant is net zo belangrijk als de binnenkant.</p>
          <p>
            De beste tijd om buiten te fotograferen is het eerste uur na zonsopgang of voor zonsondergang, ook wel bekend als het ‘Gouden
            Uur’. Op dit moment van de dag is het licht zacht (het meest diffuus) waardoor er een breed scala aan kleuren ontstaat.
          </p>
        </div>
      )}

      {tip === 5 && (
        <div>
          <h4>Tip: Fotografeer de hoeken</h4>
          <p>
            Deze techniek zorgt ervoor dat je ruimte groter lijkt en zorgt voor meer dimensie in je foto. Als je een foto maakt van een
            vlakke muur kan de ruimte kleiner lijken dan die in werkelijkheid is, terwijl hoeken helpen om een beter en realistisch
            perspectief te geven.
          </p>
        </div>
      )}
    </>
  );
}

export default Tips;
