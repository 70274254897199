import React, { useState } from "react";
import { css } from "aphrodite/no-important";
import { styles } from "./Stats.css.js";

function Stats(props) {
  const [error, setError] = useState("");
  const [iban, setIban] = useState("");
  const [pending, setPending] = useState(false);

  async function onSubmit(event) {
    event.preventDefault();
    setPending(true);
  }

  return (
    <div className={css(styles.Requests)}>
      <h1>Stats</h1>
    </div>
  );
}

export default Stats;
