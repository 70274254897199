import { StyleSheet } from "aphrodite/no-important";
import { colors, sizes, setSvgBackground } from "../../styles.js";

const styles = StyleSheet.create({
  App: {
    color: colors.textColor,
  },
  App__topbar: {
    padding: "1.5rem 0",
    display: "grid",
    marginBottom: "3rem",
    borderBottom: "1px solid #ddd",
    boxShadow: "2px 2px 80px rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
    gridTemplateColumns: `${sizes.columnSpan(5)} auto`,
    "@media (max-width: 940px)": {
      gridTemplateColumns: `${sizes.columnSpan(4)} auto`,
    },
    "@media (max-width: 420px)": {
      padding: "2rem 2rem 2rem",
      gridTemplateColumns: `none`,
    },
  },
  App__logo: {
    margin: "1rem 0 0 3rem",
    "@media (max-width: 420px)": {
      margin: "0 0 2rem",
    },
  },
  App__nav: {
    textAlign: "right",
    paddingRight: "2rem",
    "@media (max-width: 420px)": {
      paddingRight: `0`,
      textAlign: "left",
    },
  },
  App__logout: {
    background: setSvgBackground("logout", "1.1rem", "50%"),
    border: `1px solid #ddd`,
    padding: "1.3rem 1.3rem 1.3rem 3rem",
    borderRadius: "3px",
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: "1.1rem",
    color: colors.darkGray,
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    ":hover": {
      backgroundColor: "#fff",
    },
  },
  App__content: {
    maxWidth: "980px",
    "@media (min-width: 979px)": {
      margin: "0 auto",
    },
    display: "grid",
    gridGap: "2rem",
    padding: "2rem",
    gridTemplateColumns: `200px auto`,
    "@media (max-width: 940px)": {
      display: "block",
      width: "auto",
    },
    "@media (max-width: 420px)": {
      display: "block",
      width: "100%",
      gridTemplateColumns: "none",
    },
  },
  "App__content--is-not-logged-in": {
    maxWidth: "auto",
    width: "100%",
    display: "block",
    padding: "0",
  },
  App__waiting: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  App__user: {
    padding: "0 20px 0 0",
    fontSize: "1.2rem",
  },
});

export { styles };
