import React, { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { css } from "aphrodite/no-important";

import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase.js";

import Logo from "../../components/Logo.js";
import RandomImage from "../../components/RandomImage.js";
import Message from "../../components/Message.js";
import { styles as ButtonStyles } from "../../components/Button.css.js";
import { styles as AuthStyles } from "../../components/Auth.css.js";

function ForgetPassword(props) {
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");

  const location = useLocation();

  async function onSubmit(event) {
    event.preventDefault();
    setError("");
    setInfo("");

    try {
      await sendPasswordResetEmail(auth, email);
      setInfo("Er is een email naar je gestuurd met instructies!");
    } catch (err) {
      console.log(err);
      setError("Oeps! Controleer het emailadres even.");
    }
  }

  // If authenticated redirect the user to its orginal destination.
  if (auth.currentUser) {
    return <Navigate replace to={_.get(location.state, "from", "/")} />;
  }

  return (
    <div className={css(AuthStyles.Auth)}>
      <RandomImage />
      <div className={css(AuthStyles.Auth__right)}>
        <div>
          <div className={css(AuthStyles.Auth__logo)}>
            <Logo />
          </div>
          <form id="forgot-password" onSubmit={onSubmit} className={css(AuthStyles.Auth__form)}>
            <h3 className={css(AuthStyles.Auth__title)}>Wachtwoord vergeten? Vraag een nieuwe aan.</h3>
            {!!error && <Message text={error} />}
            {!!info && <p>{info}</p>}
            <div className={css(AuthStyles.Auth__row)}>
              <input
                name="email"
                type="email"
                placeholder="Je emailadres"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={css(AuthStyles.Auth__textfield)}
              />
            </div>
            <button id="submit-btn" type="submit" className={css(ButtonStyles.Button)}>
              Verstuur instructies
            </button>
            <Link to="/login" className={css(ButtonStyles["Button__ghost"])}>
              Annuleren
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
