import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import qs from "qs";
import { css } from "aphrodite/no-important";
import { applyActionCode } from "firebase/auth";

import { auth } from "../../firebase.js";
import Logo from "../../components/Logo.js";
import Message from "../../components/Message.js";
import RandomImage from "../../components/RandomImage.js";
import { styles as AuthStyles } from "../../components/Auth.css.js";

function VerifyEmail(props) {
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect((_) => {
    async function verifyEmail() {
      setInfo("");
      setError("");

      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      const actionCode = query.oobCode;

      try {
        await applyActionCode(auth, actionCode);

        const user = auth.currentUser;
        if (!user) {
          console.info("No authenticated user found.");
          setInfo("Bedankt, uw emailadres is geverifieerd!");
          navigate("/login");
          return false;
        }

        console.info("Authenticated user found refreshing token.");
        await user.reload();
        setInfo("Bedankt, uw emailadres is geverifieerd!");
        navigate("/login");
      } catch (err) {
        console.error(err);
        setError(`Uw emailadres is al geverifieerd of de link is verlopen.`);
      }
    }

    verifyEmail();
  }, []);

  // If authenticated redirect the user to its orginal destination.
  if (auth.currentUser && auth.currentUser.emailVerified) {
    return <Navigate replace to={_.get(location.state, "from", "/")} />;
  }

  return (
    <div className={css(AuthStyles.Auth)}>
      <RandomImage />
      <div className={css(AuthStyles.Auth__right)}>
        <div>
          <div className={css(AuthStyles.Auth__logo)}>
            <Logo />
          </div>
          <form id="verify-email" className={css(AuthStyles.Auth__form)}>
            <h3 className={css(AuthStyles.Auth__title)}></h3>
            {!!info && <p>{info}</p>}
            {!!error && <Message text={error} />}
            <Link to="/login" className={css(AuthStyles.Auth__link)}>
              Naar login
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
