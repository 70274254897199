import { StyleSheet } from "aphrodite/no-important";
import { colors, sizes } from "../../styles.js";

const styles = StyleSheet.create({
  Requests: {
    display: "grid",
    gridTemplateColumns: `${sizes.columnSpan(5)} ${sizes.columnSpan(10)}`,
    gridGap: "2rem",
  },
  Requests__request: {
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, .07)",
    background: colors.white,
    padding: "2rem",
    // borderBottom: `1px solid ${colors.background}`
    marginBottom: "1rem",
  },
  "Requests__request--is-selected": {
    color: colors.primary,
  },
});

export { styles };
