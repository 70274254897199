import React, { useState } from "react";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { useSortable, SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import _ from "lodash";
import { ref, uploadBytesResumable } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { nanoid } from "nanoid";
import { css } from "aphrodite/no-important";
import GracefulImage from "react-graceful-image";

import { storage } from "../../../firebase.js";
import { styles } from "./Gallery.css.js";
import Message from "../../../components/Message.js";

const imageCache = {};

function DragHandle(props) {
  const src = `https://storage.googleapis.com/feestjegeven-nl.appspot.com/venues/${props.venueId}/images/${props.imageId}-thumb.jpg`;
  return <GracefulImage src={imageCache[props.imageId] || src} className={css(styles.Gallery__thumb)} alt="" />;
}

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = { transform: CSS.Transform.toString(transform), transition };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <span {...listeners}>
        <DragHandle venueId={props.venueId} imageId={props.id} />
      </span>
      <span className={css(styles.Gallery__count)}>{props.label}</span>
      <span onClick={() => props.onImageRemove(props.id)} className={css(styles.Gallery__remove)}>
        &nbsp;
      </span>
    </div>
  );
}

function Gallery(props) {
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(false);
  const sensors = useSensors(useSensor(PointerSensor));
  const filesRef = ref(storage, `venues/${props.objectID}/images`);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setError("");

      const file = acceptedFiles[0];
      const nid = nanoid(10).toLowerCase();
      const ext = file.name.split(".").pop();
      const image = new Image();

      image.onload = function () {
        if (image.width < 1600 || image.height < 800) {
          setError(
            `De afmetingen van deze afbeelding zijn te klein (${image.width}x${image.height}), gebruik een minimale resolutie 1600x800. Zo komt uw locatie altijd mooi uit de verf!`,
          );
          return false;
        }

        imageCache[nid] = image.src;

        const task = uploadBytesResumable(ref(filesRef, `${nid}.${ext}`), file);
        task.on(
          "state_changed",
          function (snapshot) {
            setProgress(Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
          },
          function (error) {
            console.log(error);
          },
          function () {
            setProgress(0);
            props.onImageAdd(nid);
          },
        );
      };

      image.src = URL.createObjectURL(file);
    },
  });

  return (
    <div className={css(styles.Gallery)}>
      <div className={css(styles["Gallery__overview"])}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={props.onSortEnd}>
          <SortableContext items={props.images} strategy={horizontalListSortingStrategy}>
            {_.map(props.images, (id) => (
              <SortableItem key={id} id={id} onImageRemove={props.onImageRemove} venueId={props.objectID} />
            ))}
          </SortableContext>
        </DndContext>
        <div title="Nieuwe foto toevoegen" {...getRootProps({ className: "dropzone" })} className={css(styles.Gallery__upload)}>
          {progress > 1 && (
            <div className={css(styles.Gallery__progress)}>
              <span className={css(styles.Gallery__progress_count)}>{progress}%</span>
              <span
                className={css(styles.Gallery__spinner)}
                dangerouslySetInnerHTML={{
                  __html:
                    '<svg height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="#ff3b5b"><g className="nc-loop_circle-03-32" transform="rotate(334.68569248296416 16 16)"><path d="M16,32C7.17773,32,0,24.82227,0,16S7.17773,0,16,0s16,7.17773,16,16S24.82227,32,16,32z M16,4 C9.3833,4,4,9.3833,4,16s5.3833,12,12,12s12-5.3833,12-12S22.6167,4,16,4z" fill="#ff3b5b" opacity="0.4"/><path d="M32,16h-4c0-6.6167-5.3833-12-12-12V0C24.82227,0,32,7.17773,32,16z"/></g><script>!function(){function t(t){return.5&gt;t?4*t*t*t:(t-1)*(2*t-2)*(2*t-2)+1}function i(t){this.element=t,this.animationId,this.start=null,this.init()}if(!window.requestAnimationFrame){var n=null;window.requestAnimationFrame=function(t,i){var e=(new Date).getTime();n||(n=e);var a=Math.max(0,16-(e-n)),o=window.setTimeout(function(){t(e+a)},a);return n=e+a,o}}i.prototype.init=function(){var t=this;this.animationId=window.requestAnimationFrame(t.triggerAnimation.bind(t))},i.prototype.reset=function(){var t=this;window.cancelAnimationFrame(t.animationId)},i.prototype.triggerAnimation=function(i){var n=this;this.start||(this.start=i);var e=i-this.start;900&gt;e||(this.start=this.start+900),this.element.setAttribute("transform","rotate("+Math.min(900*t(e/900)/2.5,360)+" 16 16)");if(document.documentElement.contains(this.element))window.requestAnimationFrame(n.triggerAnimation.bind(n))};var e=document.getElementsByClassName("nc-loop_circle-03-32"),a=[];if(e)for(var o=0;e.length&gt;o;o++)!function(t){a.push(new i(e[t]))}(o);document.addEventListener("visibilitychange",function(){"hidden"==document.visibilityState?a.forEach(function(t){t.reset()}):a.forEach(function(t){t.init()})})}();</script></g></svg>',
                }}
              />
            </div>
          )}
          <input {...getInputProps()} />
        </div>
      </div>
      {error && <Message text={error} />}
    </div>
  );
}

export default Gallery;
