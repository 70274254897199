import React from "react";

function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="189" height="35" viewBox="0 0 189 35">
      <g fill="{colors.textColor}">
        <g transform="translate(38 7)" id="text">
          <polygon points="0.4 1.9 11.3 1.9 11.3 5.2 4.3 5.2 4.3 9.2 10.3 9.2 10.3 12.5 4.3 12.5 4.3 19.3 0.4 19.3 0.4 1.9"></polygon>
          <path d="M12.7 12.7C12.7 8.4 15.8 5.7 19 5.7 22.7 5.7 24.6 8.5 24.6 12.1 24.6 12.8 24.5 13.5 24.4 13.8L16.5 13.8C16.9 15.7 18.2 16.6 20 16.6 21 16.6 21.9 16.3 22.8 15.7L24.1 18.1C22.8 19 21 19.6 19.4 19.6 15.7 19.6 12.7 17 12.7 12.7ZM21.3 11.2C21.3 9.8 20.6 8.7 19.1 8.7 17.8 8.7 16.8 9.5 16.5 11.2L21.3 11.2Z"></path>
          <path d="M26.5 12.7C26.5 8.4 29.6 5.7 32.8 5.7 36.5 5.7 38.4 8.5 38.4 12.1 38.4 12.8 38.3 13.5 38.2 13.8L30.3 13.8C30.7 15.7 32 16.6 33.7 16.6 34.7 16.6 35.6 16.3 36.6 15.7L37.9 18.1C36.5 19 34.8 19.6 33.2 19.6 29.4 19.6 26.5 17 26.5 12.7ZM35 11.2C35 9.8 34.4 8.7 32.8 8.7 31.6 8.7 30.5 9.5 30.3 11.2L35 11.2Z"></path>
          <path d="M39.9 17.7L41.7 15.3C42.9 16.2 44 16.7 45.1 16.7 46.2 16.7 46.7 16.2 46.7 15.5 46.7 14.7 45.4 14.3 44.1 13.7 42.5 13.1 40.6 12 40.6 9.9 40.6 7.4 42.6 5.7 45.6 5.7 47.6 5.7 49.2 6.5 50.3 7.4L48.5 9.7C47.6 9.1 46.7 8.6 45.7 8.6 44.7 8.6 44.2 9 44.2 9.7 44.2 10.5 45.5 10.8 46.8 11.3 48.5 12 50.4 12.9 50.4 15.3 50.4 17.7 48.5 19.6 45 19.6 43.2 19.6 41.2 18.8 39.9 17.7Z"></path>
          <path d="M52.9 14.5L52.9 9.1 51.1 9.1 51.1 6.2 53.1 6 53.5 2.5 56.8 2.5 56.8 6 59.9 6 59.9 9.1 56.8 9.1 56.8 14.4C56.8 15.9 57.5 16.5 58.5 16.5 58.9 16.5 59.4 16.4 59.7 16.3L60.4 19.1C59.7 19.3 58.7 19.6 57.4 19.6 54.2 19.6 52.9 17.5 52.9 14.5Z"></path>
          <path d="M59.4 24.1L60.1 21.2C60.5 21.3 60.8 21.4 61.2 21.4 62.2 21.4 62.6 20.7 62.6 19.3L62.6 6 66.5 6 66.5 19.2C66.5 22.1 65.4 24.5 61.8 24.5 60.7 24.5 60 24.3 59.4 24.1ZM62.3 2.1C62.3 0.9 63.2 0 64.5 0 65.8 0 66.8 0.9 66.8 2.1 66.8 3.3 65.8 4.1 64.5 4.1 63.2 4.1 62.3 3.3 62.3 2.1Z"></path>
          <path d="M69.2 12.7C69.2 8.4 72.2 5.7 75.4 5.7 79.1 5.7 81 8.5 81 12.1 81 12.8 80.9 13.5 80.9 13.8L72.9 13.8C73.3 15.7 74.6 16.6 76.4 16.6 77.4 16.6 78.3 16.3 79.2 15.7L80.5 18.1C79.2 19 77.4 19.6 75.8 19.6 72.1 19.6 69.2 17 69.2 12.7ZM77.7 11.2C77.7 9.8 77 8.7 75.5 8.7 74.2 8.7 73.2 9.5 72.9 11.2L77.7 11.2Z"></path>
          <path d="M82.6 21.5C82.6 20.4 83.3 19.5 84.5 18.9L84.5 18.8C83.8 18.3 83.3 17.6 83.3 16.5 83.3 15.6 83.9 14.6 84.8 14L84.8 13.9C83.8 13.3 83 12 83 10.5 83 7.3 85.6 5.7 88.5 5.7 89.2 5.7 89.9 5.8 90.5 6L95.3 6 95.3 8.9 93.2 8.9C93.5 9.3 93.7 9.9 93.7 10.6 93.7 13.7 91.3 15.1 88.5 15.1 88 15.1 87.4 15 86.8 14.8 86.5 15.1 86.4 15.3 86.4 15.8 86.4 16.5 86.9 16.8 88.4 16.8L90.5 16.8C93.7 16.8 95.5 17.8 95.5 20.1 95.5 22.9 92.7 24.9 88.2 24.9 85.1 24.9 82.6 23.9 82.6 21.5ZM91.8 20.8C91.8 20 91.1 19.8 89.8 19.8L88.4 19.8C87.5 19.8 87 19.7 86.6 19.6 86.1 20 85.8 20.4 85.8 20.9 85.8 21.9 87 22.4 88.8 22.4 90.5 22.4 91.8 21.7 91.8 20.8ZM90.3 10.5C90.3 9.1 89.5 8.4 88.5 8.4 87.4 8.4 86.7 9.1 86.7 10.5 86.7 12 87.4 12.7 88.5 12.7 89.5 12.7 90.3 12 90.3 10.5Z"></path>
          <path d="M96.4 12.7C96.4 8.4 99.5 5.7 102.7 5.7 106.4 5.7 108.3 8.5 108.3 12.1 108.3 12.8 108.2 13.5 108.1 13.8L100.2 13.8C100.6 15.7 101.9 16.6 103.6 16.6 104.6 16.6 105.5 16.3 106.5 15.7L107.8 18.1C106.4 19 104.7 19.6 103.1 19.6 99.3 19.6 96.4 17 96.4 12.7ZM104.9 11.2C104.9 9.8 104.3 8.7 102.7 8.7 101.5 8.7 100.4 9.5 100.2 11.2L104.9 11.2Z"></path>
          <path d="M109.4 6L113.3 6 115.1 12.3C115.4 13.6 115.7 15 116.1 16.3L116.2 16.3C116.5 15 116.9 13.6 117.2 12.3L118.9 6 122.7 6 118.4 19.3 113.9 19.3 109.4 6Z"></path>
          <path d="M123.7 12.7C123.7 8.4 126.8 5.7 130 5.7 133.7 5.7 135.6 8.5 135.6 12.1 135.6 12.8 135.5 13.5 135.4 13.8L127.5 13.8C127.9 15.7 129.2 16.6 131 16.6 132 16.6 132.9 16.3 133.8 15.7L135.1 18.1C133.8 19 132 19.6 130.4 19.6 126.7 19.6 123.7 17 123.7 12.7ZM132.3 11.2C132.3 9.8 131.6 8.7 130.1 8.7 128.8 8.7 127.8 9.5 127.5 11.2L132.3 11.2Z"></path>
          <path d="M138.3 6L141.5 6 141.8 7.7 141.9 7.7C143 6.6 144.3 5.7 146.1 5.7 149 5.7 150.2 7.8 150.2 11.1L150.2 19.3 146.3 19.3 146.3 11.6C146.3 9.7 145.8 9.1 144.6 9.1 143.7 9.1 143.1 9.5 142.2 10.3L142.2 19.3 138.3 19.3 138.3 6 138.3 6Z"></path>
        </g>
        <path
          d="M14.9 0.1C6.7 0.1 0.1 6.7 0.1 14.8 0.1 18.9 1.7 22.6 4.4 25.3L10.2 31C11 29.5 11.8 27.7 12.3 25.6L13.1 26.6C12.6 28.6 11.8 30.3 10.9 31.8L13.1 33.9C14.1 34.9 15.7 34.9 16.7 33.9L25.4 25.3C28.1 22.6 29.7 18.9 29.7 14.8 29.7 6.7 23.1 0.1 14.9 0.1ZM22.6 16.4C21.8 20.7 17.7 23.5 13.5 22.7 13.3 22.6 13.2 22.6 13 22.6 13.5 23.1 14.1 23.5 14.8 23.6 14.8 23.6 14.8 23.6 14.8 23.6 15 24.2 15.1 24.8 15.3 25.5 13.5 25.4 12.5 25.2 10.8 24.6 11.2 23.9 11.7 23.1 12.3 22.4 8.7 21.1 6.4 17.4 7.2 13.6 8 9.3 12.1 6.5 16.3 7.3 20.6 8.1 23.4 12.2 22.6 16.4Z"
          fill="#FF3B5C"
        ></path>
      </g>
    </svg>
  );
}

export default Logo;
