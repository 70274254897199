import { StyleSheet } from "aphrodite/no-important";
import { colors, sizes, setSvgBackground } from "../styles.js";

const styles = StyleSheet.create({
  MainNav: {
    padding: "0 3rem",
    "@media (max-width: 940px)": {
      padding: "0",
      marginBottom: "3rem",
    },
  },
  MainNav__title: {
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "1.2rem",
    color: colors.primary,
    marginBottom: "2rem",
  },
  MainNav__logout: {
    border: `1px solid #ccc`,
    padding: "1rem",
    color: colors.primary,
    marginTop: "3rem",
    borderRadius: "3px",
  },

  MainNavItem: {
    display: "block",
    padding: "0.8rem 0",
    // textAlign: 'center',
    borderRadius: "6px",
    color: colors.textColor,
    // opacity: '0.4',
  },
  "MainNavItem--is-active": {
    fontWeight: 600,
    opacity: "1",
  },
  MainNavItem__link: {
    paddingTop: "0.5rem",
  },
  "MainNavItem__link--is-active": {},
  "MainNavItem__link--has-notifications": {},
  MainNavItem__icon: {},
  "MainNavItem--has-icon-edit": {
    background: setSvgBackground("edit"),
  },
});

export { styles };
