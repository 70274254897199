export default {
  types: {
    Attractieparken: "amusement-parks",
    Buitenlocaties: "outdoor-locations",
    "Clubs en discotheken": "nightclubs-and-bars",
    "Congres- en vergaderlocaties": "congress-and-meeting-locations",
    Dierenparken: "animal-parks",
    Golfbanen: "golfcourses",
    Hotels: "hotels",
    "Industriële locaties": "industrial-locations",
    "Kastelen, land- en herenhuizen": "castles-and-villas",
    "Kerken en kloosters": "churches-and-monasteries",
    "Mobiele locaties": "mobile-locations",
    "Musea en galeries": "museums-and-galleries",
    Partycentra: "party-centers",
    "Partyschepen en rederijen": "party-ships",
    Restaurants: "restaurants",
    "Schouwburgen en theaters": "theaters",
    Sportaccommodaties: "sports-facilities",
    Strandpaviljoens: "beach-bars",
    "Studio's en concertlocaties": "concert-halls",
    Evenementenlocaties: "exhibition-buildings",
    "Boerderijen en windmolens": "farms-and-windmills",
  },

  features: {
    "Gelegen aan de kust": "coast",
    "Gelegen aan het water": "waterside",
    "Gelegen in het stadscentrum": "city-centre",
    "In/outdoor activiteiten": "activities",
    "Mindervaliden toegankelijk": "wheelchair-friendly",
    "Landelijk gelegen": "rural",
    Restaurant: "restaurant",
    Terras: "terrace",
    Tuin: "garden",
    "Vaste catering": "permanent-catering",
    Zwembad: "pool",
    Overnachten: "overnight",
  },

  accessibility: {
    "Bereikbaar per boot": "boat",
    "Nabij luchthaven": "airport",
    "Nabij snelweg": "highway",
    "Bereikbaar per openbaar vervoer": "public-transport",
  },
};
