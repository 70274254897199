import { StyleSheet } from "aphrodite/no-important";
import { colors, sizes, setSvgBackground } from "../../../styles.js";

const styles = StyleSheet.create({
  Gallery: {},
  Gallery__overview: {
    padding: 0,
    position: "relative",
    listStyle: "none",
    display: "grid",
    gridTemplateColumns: `repeat(5,${sizes.columnSpan(2)})`,
    gridGap: "1rem",
    "@media (max-width: 680px)": {
      gridTemplateColumns: `repeat(3,${sizes.columnSpan(2)})`,
    },
    "@media (max-width: 480px)": {
      gridTemplateColumns: `repeat(2,${sizes.columnSpan(2)})`,
    },
    "@media (max-width: 330px)": {
      gridTemplateColumns: `none`,
    },
  },
  Gallery__thumb: {
    objectFit: "cover",
    width: sizes.columnSpan(2),
    height: sizes.columnSpan(2),
    display: "block",
    "@media (max-width: 330px)": {
      width: "100%",
      height: "100%",
    },
  },
  Gallery__remove: {
    position: "absolute",
    cursor: "pointer",
    display: "block",
    background: `${colors.primary} ${setSvgBackground("remove", "50%", "50%")}`,
    color: colors.white,
    padding: "0.5rem",
    width: "30px",
    textAlign: "center",
    margin: "-31px 0 0 0",
  },
  Gallery__remove_disabled: {
    position: "absolute",
    cursor: "pointer",
    display: "block",
    background: `${colors.primary} ${setSvgBackground("remove", "50%", "50%")}`,
    padding: "0.5rem",
    width: "30px",
    textAlign: "center",
    margin: "-31px 0 0 84px",
  },
  Gallery__upload: {
    background: setSvgBackground("plus", "50%", "50%"),
    width: sizes.columnSpan(2),
    height: sizes.columnSpan(2),
    cursor: "pointer",
    position: "relative",
    display: "block",
    border: `1px dotted ${colors.lightBrown}`,
  },
  Gallery__progress_count: {
    position: "absolute",
    top: "40px",
  },
  Gallery__count: {
    display: "none",
  },
  Gallery__progress: {
    margin: "41px 0 0 41px",
    position: "absolute",
    left: "0",
  },
});

export { styles };
